import React from "react";
import * as styles from "./index.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Article({ article }) {
  return (
    <article className={styles.article}>
      <a
        href={article.node.frontmatter.link}
        target="_blank"
        rel="noopener noreferrer"
        className="flex brand-black no-underline md:flex-col"
      >
        <div className={`${styles.left} bg-brand-black md:flex-auto relative`}>
          <div className="w-full h-full bg-center bg-no-repeat bg-cover absolute top-0 left-0">
            {article.node.frontmatter.background && (
              <GatsbyImage
                image={article.node.frontmatter.background.childImageSharp.gatsbyImageData}
                alt=""
                className="h-full" />
            )}
          </div>
        </div>
        <div className={`${styles.right} p-12 xxl:p-16 bg-brand-white relative`}>
          <h5 className="large-text font-title mb-4">
            {article.node.frontmatter.title}
          </h5>
          <p className="pb-20">{article.node.frontmatter.description}</p>
          <span className="absolute bottom-0 pb-12 xxl:pb-16 no-underline brand-black small-copy email-link hover:no-underline hover:brand-black">
            {article.node.frontmatter.link_text}
          </span>
        </div>
      </a>
    </article>
  );
}
