import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Ecosystem from "../components/Ecosystem";

let IndexPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Ecosystem" />
    <Ecosystem />
  </Layout>
);

export default IndexPage;
