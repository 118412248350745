import React from "react";
import * as styles from "./index.module.scss";
import PageTitle from "../utils/PageTitle";
import Article from "./Article";
import { useStaticQuery, graphql } from "gatsby";

export default function Ecosystem() {
  const data = useStaticQuery(graphql`query EcosystemQuery {
  allMarkdownRemark(
    filter: {frontmatter: {type: {eq: "ecosystem"}}}
    sort: {fields: frontmatter___order, order: ASC}
  ) {
    edges {
      node {
        frontmatter {
          title
          description
          link
          link_text
          background {
            childImageSharp {
              gatsbyImageData(quality: 60, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`);
  return (
    <div className="page-wrapper">
      <header className="page-section-top">
        <div className={styles.container}>
          <PageTitle title="Ecosystem" color="black" />
          <div className="max-w-6xl my-40  sm:my-20 sm:mt-24">
            <p>
              Capital is just one piece of the puzzle. We make it a point to be
              engaged in the community and foster the wider startup ecosystem in
              Asia and beyond. From accelerator programs to strategic partners,
              we work with incredible teams to support our portfolio companies
              across various areas.
            </p>
          </div>
        </div>
      </header>
      <section className="bg-brand-grey py-32 sm:pb-56" id="ecoContainer">
        <div className={`${styles.articleContainer} flex flex-col`}>
          {data.allMarkdownRemark.edges.map((article, i) => (
            <Article key={i} article={article} />
          ))}
        </div>
      </section>
    </div>
  );
}
